<!-- Toolbar -->
<mat-toolbar color="primary">
  <span>Proto</span>
</mat-toolbar>
<div>
  <p [style.fontSize.px]="fontSize">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed euismod
    est, vel feugiat nisl. Nam venenatis nibh ut blandit feugiat. Suspendisse
    pellentesque vitae tellus nec posuere. Donec at ultrices nisi, a ultricies
    sapien. Mauris tortor leo, scelerisque ut magna id, feugiat laoreet arcu. Ut
    volutpat faucibus magna. Cras sagittis eleifend commodo. In nulla nulla,
    tempus ut elementum ut, suscipit at nisi. Pellentesque habitant morbi
    tristique senectus et netus et malesuada fames ac turpis egestas. Mauris
    auctor vel nisi vel tristique. Vivamus consequat commodo lacus, et facilisis
    quam efficitur a. Ut gravida ligula vel mi pulvinar, eu semper neque
    fringilla. Nulla lacinia volutpat magna sed bibendum. Cras cursus mattis
    lorem consequat pulvinar. Pellentesque purus nunc, posuere quis laoreet id,
    luctus eget lacus. Aliquam fringilla erat id nunc suscipit malesuada. Mauris
    laoreet nisi non convallis imperdiet. Morbi sit amet sapien ante. Donec non
    est sodales, condimentum augue eget, iaculis mauris. Aenean ultricies,
    mauris at laoreet varius, elit ligula egestas felis, non efficitur arcu
    lorem sed justo. Ut dignissim diam a elit placerat dictum. Vestibulum
    pellentesque, elit at dignissim sagittis, orci est rutrum turpis, id aliquet
    orci magna at quam. Proin convallis pharetra ligula, vel dictum justo
    eleifend vitae. Sed pellentesque, augue in egestas mollis, metus turpis
    luctus lorem, ac elementum ante eros ac velit. Donec accumsan commodo nisl
    eget iaculis. Nullam ullamcorper, justo sit amet luctus porttitor, diam urna
    molestie odio, id vestibulum justo tellus et magna. Nullam eu ante libero.
    Sed vitae massa congue, suscipit arcu in, varius lacus. Curabitur sed eros
    sapien. Etiam dignissim ornare turpis et feugiat. Nullam vel consequat dui.
    Etiam vehicula ipsum id consequat efficitur. Integer auctor lectus vitae
    nisl viverra, id sagittis tortor aliquam. Sed magna arcu, congue vel lorem
    nec, interdum bibendum purus. Maecenas non nibh vel diam posuere maximus.
    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
    cubilia curae; Ut et lectus leo. Nam pharetra nisi aliquet enim dignissim,
    eu ultricies augue viverra. Duis dui tortor, pulvinar eu convallis et,
    consectetur ut tellus. Interdum et malesuada fames ac ante ipsum primis in
    faucibus. Cras ut eleifend enim, sed ullamcorper ipsum. Etiam a orci vitae
    erat lobortis tristique. Cras molestie dui id magna efficitur iaculis. Morbi
    non malesuada odio, at fermentum erat. Curabitur augue ipsum, placerat vel
    nibh vitae, congue viverra quam. Proin maximus mi eget massa scelerisque
    semper vitae ac diam. Praesent turpis dui, iaculis nec erat ut, consectetur
    tempus felis. Curabitur tempor molestie diam vitae posuere. In vel lacinia
    nisi, sit amet placerat libero. Cras augue est, imperdiet nec velit quis,
    porta semper tortor. Vivamus at imperdiet eros. Vivamus pretium sed dolor
    nec sollicitudin. Sed non leo gravida, auctor diam sit amet, luctus dui.
    Integer vitae consequat tortor. Integer facilisis, erat ac dictum porta,
    sapien elit tempus tellus, ac congue lectus velit nec massa. Aliquam erat
    volutpat. Vivamus non metus eu enim vehicula sagittis. Duis vel malesuada
    enim. Quisque ornare eu nibh malesuada elementum. Donec ut varius lorem.
    Nulla egestas luctus diam et scelerisque. Aliquam erat volutpat. Etiam
    tortor metus, condimentum ultricies porta congue, egestas quis risus. Proin
    pulvinar, ipsum id convallis semper, elit justo bibendum libero, non dapibus
    urna velit a quam.
  </p>
</div>
<div class="menu">
  <app-font-slider (editFontSizeEvent)="changeFontSize($event)" [hidden]="!showSlider"></app-font-slider>
  <app-menu (showSliderEvent)="showSlider = !showSlider"></app-menu>
</div>
